/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";
import { generateCells } from "./utils";
import { useState, useEffect } from "react";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { useToast } from "@/components/ui/use-toast";

import { WhiteBox } from "@/components/WhiteBox";
import { RemoveCrewMemberDocumentsPositionsDialog } from "@/components/dialogs/RemoveCrewMemberDocumentsPositionsDialog ";
import { EditCalendarDocumentsDialog } from "@/components/dialogs/EditCalendarDocumentsDialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogClose
} from "@/components/ui/dialog";
import { Loader, CheckIcon, AlertCircleIcon, Download } from "lucide-react";
import {
	useEditChangeDayMutation
} from "@/app/api/slices/positionsApiSlice";
import { 
	type RotationDatesInfoInterface, 
	type CalendarPartInterface, 
	type RotationInterface,
	type CrewMemberInterface
} from "@/types/Calendar";
import { legend, prepareDate, getEmptyDaysCount, getEmptyFirstStartDate } from "@/utils/calendarUtils";

import * as Checkbox from '@radix-ui/react-checkbox';
import { cn } from "@/lib/utils";

type Props = { 
	rotation: undefined | null | string,
	pageHandler: (page: {dir: string, currentPage: number | undefined}) => void,
	page: undefined | number,
	maxPages: number,
	perPage: number,
	perPageHandler: (value: string) => void,
	isLoading: boolean,
	showDayKindModalHandler: ({startDate}:{startDate: Date}) => void,
	calendarDataGroup?: CrewMemberInterface[] | undefined,
	vesselId: number | string | undefined,
	departmentId: number | string | undefined,
	positionId: number | string | undefined,
	contractId: number | string | undefined,
	rotationPeriodType: string | undefined,
};

const colorPallete = {
	header: "bg-slate-100",
	cell: "bg-slate-50"
};

export const Calendar = ({rotationPeriodType, vesselId, departmentId, positionId, contractId, calendarDataGroup, rotation, isLoading, pageHandler, page, maxPages, perPage, perPageHandler, showDayKindModalHandler }: Props) => {
	const [transformedData, setTransformedData] = useState<CalendarPartInterface[][] | []>([]);
	const { toast } = useToast();
	const [startDate, setStartDate] = useState<string | undefined>();
	const [endDate, setEndDate] = useState<string | undefined>();
	const [applyToAll, setApplyToAll] = useState<boolean>(true);

	const [showChangeDayModal, setShowChangeDayModal] = useState<boolean>(false);
	const [hoveredCell, setHoveredCell] = useState<string | undefined>();

	const applyToAllHandler = (checked: boolean) => setApplyToAll(checked);
	const [showDocumentModal, setShowDocumentModal] = useState(false);
	const [document, setDocument] = useState<any>(null);
	const [userId, setUserId] = useState<string | undefined>();

	useEffect(() => {
		setStartDate(undefined);
		setEndDate(undefined);
	}, [departmentId, positionId, contractId])

	const openDocumentModal = (data: any) => {
		if(!calendarDataGroup) return;
		const findDocBatchData = calendarDataGroup?.reduce((acc, crewData) => {
			if(!crewData.documents) return acc;
			const document = crewData.documents.find((doc: any) => doc.id === data);
			if(!document) return acc;
	
			acc = document;
			return acc;
		}, {})

		const findUserId = calendarDataGroup?.find((crewData) => crewData.documents?.find((doc: any) => doc.id === data));
		if(!findUserId) return;

		setUserId(String(findUserId.id));
		setDocument(findDocBatchData);
		setShowDocumentModal(true);
	}

	const onCloseDocumentModal = () => {
		setDocument(null);
		setShowDocumentModal(false);
	}

	const isRotationLengthMirrored = (rotation: string | undefined | null) => {
		if(!rotation) return null;

		const [onboardWeeks, offBoardWeeks] = rotation.split('/');
		if(onboardWeeks === offBoardWeeks) return true;
		return false;
	};

	const calculateHoveredCells = (startDate: string | undefined, hoveredCell: string | undefined) => {
		if(!startDate || !hoveredCell) return [];
		
		const startDateObj = new Date(startDate);
		const hoveredCellObj = new Date(hoveredCell);
		const diffTime = Math.abs(hoveredCellObj.getTime() - startDateObj.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const days = Array.from({ length: diffDays }, (_, i) => i + 1);
		const dates = days.map((day) => {
			const date = new Date(startDate);
			if( startDateObj > hoveredCellObj) {
				date.setDate(date.getDate() - day);
			} else {
				date.setDate(date.getDate() + day);
			}
			return format(date, "yyyy-MM-dd");
		});
		return dates;
	}
	
	const startDateHandler = (date: string) => {
		const isSame = startDate === date;
		setStartDate(isSame ? undefined : date);

		if(isSame) setEndDate(undefined);

		if(!isSame) {
			const formattedDate = format(new Date(date), "yyyy-MM-dd");
			const element = window.document.querySelector(`[data-cell-date="${formattedDate}"]`);
			if(element) {
				element.scrollIntoView({ 
					behavior: "smooth",
					block: 'center',
					inline: 'center'
				});
			}
		}
	}

	const [editChangeDay, { isLoading: isLoadingEditChangeDay }] = useEditChangeDayMutation();
	const handleShiftRotations = async () => {
		if(!vesselId || !departmentId || !positionId || !contractId || !startDate || !endDate) return;

		const reqBody = { 
			vessel_id: vesselId,
			department_id: departmentId,
			position_id: positionId,
			data: {
				from: startDate,
				to: endDate,
				id: contractId,
				applyToAll: isRotationLengthMirrored(rotation) ? applyToAll : false
			}
		}
		await editChangeDay(reqBody)
		.unwrap()
		.then(() => {
			setShowChangeDayModal(false);
			setStartDate(undefined);
			setEndDate(undefined);

			toast({
				variant: "success",
				title: "Successfully changed rotation change day.",
			});
		})
		.catch((error) => handleServerErrors(error, toast));
	}

	const endDateHandler = (date: string) => {
		setEndDate(prevDate => date === prevDate ? undefined : date);
		setShowChangeDayModal(true);
	}

	const showChangeDayModalHandler = () => {
		if(isLoadingEditChangeDay) return;
		setShowChangeDayModal(false);
		setEndDate(undefined);
	}

	const rotationsInfo = calendarDataGroup?.reduce<{[key: number]: {onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}}>((acc, rotation) => {
		rotation.rotations.forEach(({ id, onboard, offboard }: {id: number, onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}) => {
			acc[id] = { onboard, offboard};
		});

		return acc;
	}, {});

	const crewData = calendarDataGroup?.map(({id, name}: {id: number, name: string}) => {
		return {
			id,
			name
		}
	});

	const getRotationsDayChanges = (calendarDataGroup: CrewMemberInterface[] | undefined) => {
		if(!calendarDataGroup) return;
		const rotations = calendarDataGroup.map(({rotations}: {rotations: RotationInterface[]}) => {
			return rotations.map(({calendar}: {calendar: CalendarPartInterface[]}) => {
				return calendar.reduce((acc: string[], {type, start_date}) => {
					if(["change_day", "travel_day_change_day"].includes(type)) {
						acc.push(format(start_date, "yyyy-MM-dd"));
					}
					return acc;
				}, [])
			});
		});

		const uniqueDates = Array.from(new Set(rotations.flat(2)));
		const sortedIntoYears = uniqueDates.reduce((acc: {[key: string]: string[]}, date: string) => {
			const year = new Date(date).getFullYear();
			if(!acc[year]) {
				acc[year] = [date];
			} else {
				acc[year].push(date);
			}
			return acc;
		}, {});
		const sortedKeys = Object.keys(sortedIntoYears).sort((a, b) => Number(a) - Number(b));
		const preparedDates = sortedKeys.map((year) => sortedIntoYears[year]);
		
		return preparedDates;
	};

	const rotationsDatesRanges = calendarDataGroup?.map((calendarData) => {
		const dateRanges = calendarData.rotations.map((rotation) => {
			const preparedDateRanges: {onboard?: {start_date: string, end_date: string}, offboard?: {start_date: string, end_date: string}} = {};

			if(rotation.onboard?.start_date && rotation.onboard?.end_date) {
				preparedDateRanges.onboard = {
					start_date: prepareDate(rotation.onboard.start_date, 'start_date'),
					end_date: prepareDate(rotation.onboard.end_date, 'end_date')
				}
			}
			if(rotation.offboard?.start_date && rotation.offboard?.end_date) {
				preparedDateRanges.offboard = {
					start_date: prepareDate(rotation.offboard.start_date, 'start_date'),
					end_date: prepareDate(rotation.offboard.end_date, 'end_date')
				}
			}
			return preparedDateRanges;
		});

		return {
			...calendarData,
			rotations: dateRanges
		}
	})

	useEffect(() => {
		if(Array.isArray(calendarDataGroup) && calendarDataGroup.length) {
			const temp_calendarDataGroup = calendarDataGroup.map(({id, name, rotations}: CrewMemberInterface) => {
				const mergedRotations = rotations.reduce((acc: CalendarPartInterface[] | [], rotation: RotationInterface ) => {
					return [...acc, ...rotation.calendar.map((calendar) => ({...calendar, rotation_id: rotation.id, crew_member_id: id, crew_member_name: name}))];
				}, []);

				if(mergedRotations.length !== 0) {
					const firstDateInData = new Date(mergedRotations[0]?.start_date)?.getDay();
					const emptyDaysCount = getEmptyDaysCount(firstDateInData);
					const emptyFirstStartDate = getEmptyFirstStartDate(mergedRotations[0].start_date, emptyDaysCount);

					if(emptyDaysCount !== 0) {
						const firstRecordInArray = {
							type: "empty_day",
							start_date: format(emptyFirstStartDate, "yyyy-MM-dd"),
							duration: emptyDaysCount
						}
						return [firstRecordInArray, ...mergedRotations]
					} else {
						return mergedRotations
					}
				} else {
					return [];
				}

			});

			setTransformedData(temp_calendarDataGroup);
		}
	}, [calendarDataGroup]);

	if(!isLoading && !calendarDataGroup) {
		<div className="flex grow items-center justify-center">
			<span>No data available</span>
		</div>
	}

	return (
		<WhiteBox className="relative flex flex-col min-h-96 grow pt-0">
			{!isLoading && contractId && (
				<div className="mb-3 relative sticky top-0 z-10 w-full bg-white pt-4">
					<div className="flex h-24">
						<div className="grow flex justify-between">
							<div className="me-3 h-[68px] pb-2 flex items-end">
								<Button disabled={(page ? (page <= 1) : true) || isLoading} onClick={ () => pageHandler({dir: "prev", currentPage: page})}>
									Previous
								</Button>
							</div>
					
							<div className="grow relative">	
								<div className="absolute w-full flex justify-center">

									<div className="flex scroll overflow-x-auto">
										{ getRotationsDayChanges(calendarDataGroup)?.map((dates: string[], index: number, arr: string[][]) => (
											<div key={index} className={`pb-2 ${ arr.length === 1 ? "" : index === 0 ? "pe-3" : "ps-3 pb-2 border-l-2" }`}>
												
												<div className={`text-xs font-semibold mb-1 ${ arr.length === 1 ? "text-center" : index === 0 ? "text-end" : "text-start" }`}>
													{new Date(dates[0]).getFullYear()}
												</div>

												<div className="flex items-center">
													{dates.map((date: string) => (
														<Button key={date} className={`me-1 last:me-0 ${ date === startDate ? "bg-orange-500" : "" }`} onClick={() => startDateHandler(date)}>
															{new Date(date).toLocaleDateString("en-GB", { day: "numeric", month: "short"})}
														</Button>
													))}
												</div>
												
												
											</div>
										
										))}
									</div>

								</div>
							</div>

							<div className="ms-3 h-[68px] pb-2 flex items-end">
								<Button disabled={(page ? (page >= maxPages) : true) || isLoading} onClick={ () => pageHandler({dir: "next", currentPage: page})}>
									Next
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="relative flex grow">
				{(isLoading) && (
					<div
						className="pt-4 absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-md"
						style={{ background: "rgba(255,255,255,0.6)", backdropFilter: "blur(2px)" }}
					>
						<Loader className="animate-spin duration-2000" color="#000" />
					</div>
				)}

				{!isLoading && !contractId && (
					<div className="pt-4 flex grow items-center justify-center">
						<span className="text-sm">Select department, position and leave plan</span>
					</div>
				)}

				{!isLoading && contractId && (
					<div className="overflow-x-auto pb-2 w-full">
						<div className="w-full relative p-1" style={{ minWidth: 500 }}>
							<Dialog open={showChangeDayModal} onOpenChange={showChangeDayModalHandler} defaultOpen={false}>
								<DialogContent className="sm:max-w-[500px]">
									<DialogHeader>
										<DialogTitle>Are you absolutely sure?</DialogTitle>
										<DialogDescription>
											This action will edit change day from <b>{startDate}</b> to <b>{endDate}</b>.
										</DialogDescription>
									</DialogHeader>


									<div className={cn(`mt-2 flex items-center`, isRotationLengthMirrored(rotation) ? "" : "hidden")} >
										<div className="me-2">
											<Checkbox.Root 
												className="flex h-7 w-7 items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground justify-start text-left font-normal text-muted-foreground"
												onCheckedChange={applyToAllHandler}
												checked={applyToAll}
												disabled={isLoadingEditChangeDay}
												defaultChecked={true}
											>
												<Checkbox.Indicator className="CheckboxIndicator">
													<CheckIcon className="w-5 h-5 text-rose-600" />
												</Checkbox.Indicator>
											</Checkbox.Root>
										</div>

										<label className="text-sm text-rose-600 font-semibold flex items-center">
											<AlertCircleIcon className="me-1"/>
											Adapt calendar to the manual change
										</label>
									</div>
									
									
									<div className="flex justify-between">
										<DialogClose asChild>
											<Button type="button" variant="secondary">
												Cancel
											</Button>
										</DialogClose>
										<Button onClick={handleShiftRotations} disabled={isLoadingEditChangeDay}>
											<ButtonLoader isLoading={isLoadingEditChangeDay} />
											Continue
										</Button>
										</div>

								</DialogContent>
							</Dialog>

							<Dialog open={showDocumentModal} onOpenChange={onCloseDocumentModal} defaultOpen={false}>
								<DialogContent className="sm:max-w-[500px]">
									<DialogHeader>
										<DialogTitle>Document</DialogTitle>
									</DialogHeader>

									<hr />

									{document && (
										<div className="space-y-2">
												<div className="flex items-center justify-start space-x-4 sm:justify-between">
													
													<div
														className="flex flex-col h-auto w-full flex-wrap "
													>
														<h4 className="text-left font-semibold sm:w-auto">
															Title: {document.title}
														</h4>

														<div className="text-sm flex flex-col sm:flex-nowrap sm:justify-center">
															<span className="hidden font-normal sm:inline-block">
																<span className="font-semibold">Created at:</span>{" "}
																{format(document.createdAt, "yyyy-MM-dd")}
															</span>
															<span className="font-normal">
																<span className="font-semibold">Updated at:</span>{" "}
																{format(document.updatedAt, "yyyy-MM-dd")}
															</span>
														</div>
													</div>
													
													<div className="flex items-center gap-1">
														<RemoveCrewMemberDocumentsPositionsDialog userId={userId} documentId={document.id} onSuccessCallback={onCloseDocumentModal}/>
														<EditCalendarDocumentsDialog userId={userId} document={document} onSuccessCallback={onCloseDocumentModal}/>
													</div>
												</div>

												{ document.end_date && (
													<div className="">
														<h3 className="font-semibold">Date Range:</h3>
														<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
															<span>{format(document.start_date, "yyyy-MM-dd")} &ndash; {format(document.end_date || document.start_date, "yyyy-MM-dd")}</span>
														</div>
													</div>
												)}
												
												<div className="">
													<h3 className="font-semibold">Documents:</h3>
													<ul className="list-inside list-decimal">
														{document.files.map((file: {name: string, uploadedBy: string, url: string}) => {
															return (
																<li
																	key={file.name}
																	className="my-1 flex items-center justify-between rounded-sm bg-gray-100 pl-2"
																>
																	<div
																		className="truncate text-sm flex items-center"
																		title={`${file.name} &ndash; uploaded by ${file.uploadedBy}`}
																	>
																		<span className="truncate inline-block max:w-20 sm:max-w-40">{file.name}</span> &ndash; uploaded by {file.uploadedBy}
																	</div>
																	
																	<div>
																		<Button
																			variant="outline"
																			size="icon"
																			asChild
																			style={{ background: "#4CAF50", width: "28px", height: "28px" }}
																		>
																			<a className="flex items-center justify-center" href={file.url}>
																				<Download color="#fff" size={20} />
																			</a>
																		</Button>
																	</div>
																</li>
															);
														})}
													</ul>
												</div>
												<div className="">
													<h3 className="font-semibold">Description:</h3>
													<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
														<span>{document.description}</span>
													</div>
												</div>
										</div>
									)}
								</DialogContent>
							</Dialog>

							{ isLoading && (
								<div className="z-10 absolute inset-0 flex h-full w-full items-center justify-center rounded-md" style={{background: "rgba(255,255,255,0.3)", backdropFilter: "blur(2px)"}}>
									<Loader className="animate-spin duration-2000" color="#000" />
								</div>
							)}

							<div className="flex flex-wrap">
								<div className="flex grow me-3 mb-3">
									{legend.map((item) => (
										<div className="flex items-center justify-content me-3" key={item.type}>
											<div className={`w-4 h-4 me-1 border rounded-md ${item.color}`}></div>
											<label className="text-xs text-nowrap ">{item.name}</label>
										</div>
									))}
									
								</div>
								<div className="mb-3">
									<div className="flex items-center">
										<label className="text-sm text-nowrap me-3">Rotations per page</label>
										<Select onValueChange={perPageHandler} value={String(perPage)}>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
											<SelectContent>
												<SelectGroup>
													{["2","3","5"].map((rotations_count) => (
														<SelectItem key={rotations_count} value={String(rotations_count)} defaultChecked={rotations_count === String(perPage)}>{rotations_count}</SelectItem>
													))}
												</SelectGroup>
											</SelectContent>
										</Select>
									</div>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-1 md:gap-2 font-medium">

								<div className="py-2 rounded-md border border-gray-300 text-center col-span-2">
									<div className="flex flex-col">
										<div className="text-center text-xs">Month</div>
										<div className="text-center text-xs">Year</div>
									</div>
								</div>
								<div className="py-2 rounded-md border border-gray-300 text-center col-span-3">
									<div className="flex flex-col">
										<div className="text-center text-xs">Employee</div>
										<div className="text-center text-xs">
											{ (rotationPeriodType === "months") && "Rotation month" }
											{ (rotationPeriodType === "weeks") && "Rotation week" }
											{ (rotationPeriodType === "leave_allowance") && "Week" }
										</div>
									</div>
								</div>

								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Mon</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Tue</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Wed</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Thu</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Fri</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Sat</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Sun</div>
							</div>
							<div className="mt-2 grid grid-cols-12 gap-1 md:gap-2 ctrl__calendar">
								{
									generateCells({
										page,
										rotationsDatesRanges,
										hoveredCells: calculateHoveredCells(startDate, hoveredCell), 
										setHoveredCell, 
										changeDayStartDate: startDate, 
										endDate, 
										endDateHandler, 
										crewData, 
										data: transformedData, 
										showDayKindModalHandler, 
										rotation, 
										rotationsInfo,
										crewDocuments: calendarDataGroup,
										openDocumentModal,
										rotationPeriodType,
										cellColor: colorPallete.cell
									})
								}
							</div>
						</div>

					</div>
				)}
			</div>
		</WhiteBox>
	);
};
